
// Generated by dayjs-nuxt-module
import dayjs from 'dayjs'
import updateLocale from 'dayjs/plugin/updateLocale'

import 'dayjs/locale/ja'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import dayOfYear from 'dayjs/plugin/dayOfYear'
import relativeTime from 'dayjs/plugin/relativeTime'


dayjs.extend(updateLocale)

dayjs.extend(customParseFormat)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(dayOfYear)
dayjs.extend(relativeTime)

dayjs.tz.setDefault('Asia/Tokyo')

// defaultLocale: "ja"


dayjs.updateLocale("ja")
dayjs.locale('ja')


export default dayjs
