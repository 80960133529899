<template>
	<NuxtPwaManifest />
	<NuxtLayout>
		<NuxtRouteAnnouncer />
		<NuxtPage />
	</NuxtLayout>
</template>

<script setup>

const { data: response, pending, error, refresh } = await useAPIFetch("/api/queryLogin",{
	method:"POST",
})
console.log(response.value);

const login = useState('login', () => response.value?.result?.login);

</script>

<style lang="less">
@import url("~/assets/css/style.less");
</style>