export const appHead = {"meta":[{"charset":"utf-8"},{"http-equiv":"X-UA-Compatible","content":"IE=edge,chrome=1"},{"http-equiv":"content-language","content":"ja"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"format-detection","content":"telephone=no"},{"name":"description","content":"日本酒フェス 茨城愛があふれる会 2024/9/28 イベント告知ページ"},{"name":"author","content":"株式会社PATURN"},{"property":"og:title","content":"日本酒フェス 茨城愛があふれる会 2024/9/28"},{"property":"og:type","content":"article"},{"property":"og:description","content":"日本酒フェス 茨城愛があふれる会 2024/9/28 イベント告知ページ"},{"property":"og:site_name","content":"shakeme"}],"link":[{"rel":"icon","type":"image/vnd.microsoft.icon","href":"/favicon.ico","sizes":"32x32"},{"rel":"icon","type":"image/svg+xml","href":"/icon.svg"},{"rel":"apple-touch-icon","type":"image/vnd.microsoft.icon","href":"/apple-touch-icon.png"},{"rel":"preconnect","href":"https://fonts.googleapis.com"},{"rel":"preconnect","href":"https://fonts.gstatic.com","crossorigin":""},{"rel":"stylesheet","href":"https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap"},{"rel":"stylesheet","href":"https://fonts.googleapis.com/css2?family=Zen+Kaku+Gothic+Antique:wght@400;700&display=swap"}],"style":[],"script":[],"noscript":[],"title":"日本酒フェス 茨城愛があふれる会 2024/9/28","htmlAttrs":{"lang":"ja","dir":"ltr"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000