import revive_payload_client_4sVQNw7RlN from "D:/workspace/shakeme/client/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "D:/workspace/shakeme/client/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "D:/workspace/shakeme/client/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "D:/workspace/shakeme/client/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "D:/workspace/shakeme/client/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "D:/workspace/shakeme/client/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "D:/workspace/shakeme/client/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "D:/workspace/shakeme/client/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_8SbxDRbG6Y from "D:/workspace/shakeme/client/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import pwa_icons_Y6agwlnXZq from "D:/workspace/shakeme/client/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa-icons.mjs";
import pwa_client_Eorgdr12jA from "D:/workspace/shakeme/client/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import i18n_yfWm7jX06p from "D:/workspace/shakeme/client/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "D:/workspace/shakeme/client/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_AUP22rrBAc from "D:/workspace/shakeme/client/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import scrollto_hI03cYU8FB from "D:/workspace/shakeme/client/plugins/scrollto.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_8SbxDRbG6Y,
  pwa_icons_Y6agwlnXZq,
  pwa_client_Eorgdr12jA,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  plugin_AUP22rrBAc,
  scrollto_hI03cYU8FB
]